import React, { useEffect, useState } from 'react';
import {
  faEnvelopeOpen,
  faUserGraduate,
  faFishingRod,
  faCirclePlus,
  faList,
  faBrakeWarning,
  faMonitorWaveform,
  faLink,
  faPaperPlane,
  faTrash,
  faClock,
  faRocketLaunch,
  faFlagCheckered,
} from '@fortawesome/pro-regular-svg-icons';

import { useDispatch } from 'react-redux';
import { useTranslation } from '../../../i18n';
import { Stat, SCSummaryCard, TextBold } from './SummaryCard.style';
import SectionCard from '../../molecules/SectionCard/SectionCard';
import usePhishingTrainingType from '../../../hooks/phishingTrainingType/usePhishingTrainingType';
import Button from '../../atoms/Button/Button';
import useCampaign from '../../../hooks/useCampaign/useCampaign';
import Icon from '../../atoms/Icon/Icon';
import useBreachNotificationType from '../../../hooks/breachNotificationType/useBreachNotificationType';
import { TrackButton } from '../../../track';
import { getDateFormat } from '../../../utils/format/dataFormat';
import useSection from '../../../hooks/useSection/useSection';
import { showPopUp } from '../../../redux/actions/popUp.actions';

const SummaryCard = ({ campaign, trainingResults }) => {
  const { section } = useSection();
  const dispatch = useDispatch();
  const i18n = useTranslation();
  const [page, setPage] = useState(section || '');
  const { phishingTraining } = usePhishingTrainingType();
  const { breachNotification, onClickEmployees } = useBreachNotificationType();
  const {
    onClickCampain,
    onClickTeam,
    sendCampaignResults,
    getNumberTrainingsCompleted,
    getCampaignSummary,
  } = useCampaign();

  const campaignSummary = getCampaignSummary(campaign);
  const numberTrainingsCompleted = getNumberTrainingsCompleted(
    campaign,
    trainingResults
  );

  useEffect(() => {
    if (campaign) {
      setPage('campaign');
    } else {
      setPage(section);
    }
  }, [section]);

  const summaryData = {
    campaign: {
      headerIcon: faFishingRod,
      headerTitle: `${i18n.t(
        'phishingSimulator.campaignSummary.title'
      )} '${i18n.t(
        `phishingSimulator.campaigns.campaingTypes.${
          campaign?.campaign_type?.split('-')[0]
        }`
      )}'`,
      stats: [
        {
          icon: faEnvelopeOpen,
          subtitle: campaignSummary.emailsSent || '0',
          text: i18n.t('phishingSimulator.campaignSummary.emailsSent'),
        },
        {
          icon: faLink,
          subtitle: campaignSummary.emailsClicked || '0',
          text: i18n.t('phishingSimulator.campaignSummary.linksClicked'),
        },
        {
          icon: faUserGraduate,
          subtitle: numberTrainingsCompleted || '0',
          text: i18n.t('phishingSimulator.campaignSummary.trainingsCompleted'),
        },
        {
          icon: faClock,
          subtitle:
            campaignSummary.launchDate &&
            getDateFormat(campaignSummary.launchDate.toDate()),
          text: i18n.t('phishingSimulator.campaignSummary.lastEmailClicked'),
        },
      ],
      onClickPrimary: () => {
        sendCampaignResults(campaign);
        TrackButton('phishingSimulator.campaignSummary.sendResults');
      },
      iconPrimary: faPaperPlane,
      textButtonPrimary: i18n.t(
        'phishingSimulator.campaignSummary.sendResults'
      ),
      onClickSecondary: () => {
        dispatch(showPopUp('removeCampaign', campaign.campaign_id));
      },
      iconSecondary: faTrash,
      textButtonSecondary: i18n.t(
        'phishingSimulator.campaignSummary.deleteButton'
      ),
    },
    phishingsimulator: {
      headerIcon: faFishingRod,
      headerTitle: i18n.t('phishingSimulator.phishingSummary'),
      stats: [
        {
          icon: faRocketLaunch,
          subtitle: phishingTraining?.currentCampaigns || '0',
          text: i18n.t('phishingSimulator.summary.campaignsRunning'),
        },
        {
          icon: faFlagCheckered,
          subtitle: phishingTraining?.completedCampaigns || '0',
          text: i18n.t('phishingSimulator.summary.campaignsCompleted'),
        },
        {
          icon: faUserGraduate,
          subtitle: phishingTraining?.completedTrainings || '0',
          text: i18n.t('phishingSimulator.summary.usersCompletedTraining'),
        },
        {
          icon: faClock,
          subtitle:
            (phishingTraining?.lastDateCampaign &&
              getDateFormat(phishingTraining?.lastDateCampaign?.toDate())) ||
            '-',
          text: i18n.t('phishingSimulator.summary.dateOfLastCampaign'),
        },
      ],
      onClickPrimary: onClickCampain,
      iconPrimary: faCirclePlus,
      textButtonPrimary: i18n.t('phishingSimulator.campaigns.add'),
      buttonColor: 'defaultInfoBanner',
      onClickSecondary: onClickTeam,
      iconSecondary: faList,
      textButtonSecondary: i18n.t('phishingSimulator.campaigns.teamSettings'),
    },
    'data-alert': {
      headerIcon: faList,
      headerTitle: i18n.t('breachNotification.shortBreachNotification'),
      stats: [
        {
          icon: faMonitorWaveform,
          subtitle: breachNotification?.totalMonitoredEmail || '0',
          text: i18n.t('breachNotification.leaksSummary.monitoredEmail'),
        },
        {
          icon: faEnvelopeOpen,
          subtitle: breachNotification?.compromisedEmail || '0',
          text: i18n.t('breachNotification.leaksSummary.compromisedEmail'),
        },
        {
          icon: faBrakeWarning,
          subtitle: breachNotification?.totalLeaks || '0',
          text: i18n.t('breachNotification.leaksSummary.totalLeaks'),
        },
        {
          icon: faClock,
          subtitle: breachNotification?.lastDateLeaks
            ? getDateFormat(breachNotification?.lastDateLeaks.toDate())
            : '-',
          text: i18n.t('breachNotification.leaksSummary.lastDateLeaks'),
        },
      ],
      onClickPrimary: onClickEmployees,
      iconPrimary: faList,
      textButtonPrimary: i18n.t('breachNotification.addRemoveUser'),
      textSecondary: (
        <TextBold>
          {i18n.t('breachNotification.dataDisclaimer')}{' '}
          <b>haveibeenpwned.com</b>
        </TextBold>
      ),
    },
  };

  return (
    <SCSummaryCard>
      <SectionCard
        headerIcon={summaryData[page].headerIcon}
        headerTitle={summaryData[page].headerTitle}>
        <div className="summary-flex">
          {summaryData[page].stats.map(({ text, icon, subtitle }) => (
            <Stat key={text}>
              <Icon iconName={icon} />
              <div className="summary-text">
                <p>{subtitle}</p>
                {/* Reemplazamos el primer espacio por un salto de linea para introducir un salto de linea después de la primera palabra */}
                <span>{text.replace(' ', '\n')}</span>
              </div>
            </Stat>
          ))}
        </div>
        <div className="summary-buttons">
          {summaryData[page].onClickPrimary && (
            <Button
              onClick={summaryData[page].onClickPrimary}
              icon={summaryData[page].iconPrimary}
              text={summaryData[page].textButtonPrimary}
              size="mid"
              color={summaryData[page].buttonColor ?? 'white'}
            />
          )}
          {summaryData[page].onClickSecondary && (
            <Button
              onClick={summaryData[page].onClickSecondary}
              icon={summaryData[page].iconSecondary}
              text={summaryData[page].textButtonSecondary}
              size="mid"
              color="white"
            />
          )}
          {summaryData[page].textSecondary && summaryData[page].textSecondary}
        </div>
      </SectionCard>
    </SCSummaryCard>
  );
};

export default SummaryCard;
