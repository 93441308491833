import React, { useEffect } from 'react';
import useAuth from '../../hooks/auth/useAuth';

const SignOutPage = () => {
  const { signOutFromApp } = useAuth();

  useEffect(signOutFromApp, []);

  return <></>;
};

export default SignOutPage;
