import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/atoms/Button/Button';
import { createCampaign } from '../../components/Dashboard/clientHelper';
import SectionLayout from '../../components/layouts/SectionLayout/SectionLayout';
import BackButton from '../../components/molecules/BackButton/BackButton';
import NewPhisingCampaignDateCard from '../../components/organisms/NewPhisingCampaignDateCard/NewPhisingCampaignDateCard';
import NewPhisingCampaignEmployeeSelectorCard from '../../components/organisms/NewPhisingCampaignEmployeeSelectorCard/NewPhisingCampaignEmployeeSelectorCard';
import NewPhisingCampaignTypeCard from '../../components/organisms/NewPhisingCampaignTypeCard/NewPhisingCampaignTypeCard';
import useCampaign from '../../hooks/useCampaign/useCampaign';
import { useTranslation } from '../../i18n';
import { showPopUp } from '../../redux/actions/popUp.actions';
import SCNewPhisingSimulator from './NewPhisingSimulator.style';

const NewPhisingSimulator = () => {
  const i18n = useTranslation();
  const client = useSelector((redux) => redux.client);
  const navigate = useNavigate();
  const user = useSelector((redux) => redux.user);
  const dispatch = useDispatch();

  const [newPhisingCampaignType, setNewPhisingCampaignType] =
    useState(undefined);
  const [newPhisingCampaignEmployee, setNewPhisingCampaignEmployee] = useState(
    []
  );
  const [launchDate, setLaunchDate] = useState();
  const { PHISHING_CAMPAINGS } = useCampaign();

  const startCampaign = async () => {
    const MAX_LIMIT = 10;
    const deferred = newPhisingCampaignEmployee.length > MAX_LIMIT;

    try {
      await createCampaign(
        {
          campaign_type: newPhisingCampaignType,
          campaign_name: i18n.t(
            `phishingSimulator.campaigns.campaingTypes.${PHISHING_CAMPAINGS[newPhisingCampaignType].name}`
          ),
          campaign_targets: 'Selected',
          campaign_recipients: newPhisingCampaignEmployee,
          campaign_launch_date: launchDate,
          deferred,
        },
        true,
        client,
        user
      );

      dispatch(
        showPopUp('notification', {
          notificationType: 'success',
          title: deferred
            ? i18n.t('common.processingRequest')
            : i18n.t('common.processedRequest'),
          text: deferred
            ? i18n.t('phishingSimulator.campaigns.create.processingRequestText')
            : i18n.t('phishingSimulator.campaigns.create.processedRequestText'),
          onButtonClick: () => {
            navigate('/client/phishingsimulator');
          },
        })
      );
    } catch (e) {
      dispatch(
        showPopUp('notification', {
          notificationType: 'error',
          title: i18n.t('common.error'),
          text: i18n.t('common.errorTryLater'),
        })
      );
    }
  };

  return (
    <SectionLayout>
      <SCNewPhisingSimulator>
        <div className="section-back-button">
          <BackButton />
        </div>

        <div className="section-organism">
          <NewPhisingCampaignTypeCard onChange={setNewPhisingCampaignType} />
        </div>

        <div className="section-organism">
          <NewPhisingCampaignEmployeeSelectorCard
            onChange={setNewPhisingCampaignEmployee}
            nSelectedEmails={newPhisingCampaignEmployee.length}
          />
        </div>

        <div className="section-organism">
          <NewPhisingCampaignDateCard onChange={setLaunchDate} />
        </div>

        <div className="section-organism">
          <div className="NewPhisingSimulator_Action">
            <Button
              color="bluishGrey"
              size="full"
              text={i18n.t('phishingSimulator.campaigns.create.release')}
              onClick={() => {
                startCampaign();
              }}
              disabled={
                !newPhisingCampaignType ||
                newPhisingCampaignEmployee.length === 0 ||
                !launchDate
              }
            />
          </div>
        </div>
      </SCNewPhisingSimulator>
    </SectionLayout>
  );
};

export default NewPhisingSimulator;
