import { SHOW_POP_UP } from './types';

/**
 * popUpCode = 'addEmail' | 'removeEmail' | 'addWebsite' | 'removeWebsite' | 'addCampaign' | 'removeCampaign'
| 'textWithoutButtons' | 'addUserInEmailList' | 'editUserInEmailList'| 'removeSupplier'
| 'addSupplier' | 'tutorial' | 'noButtons'
 */
export const showPopUp =
  (popUpCode, item, preventBackgroundClose) => (dispatch) => {
    dispatch({
      type: SHOW_POP_UP,
      payload: { popUpCode, item, backgroundClosable: preventBackgroundClose },
    });
  };

export default true;
