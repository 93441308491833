import axios from 'axios';

// eslint-disable-next-line import/prefer-default-export
export const signUp = async (
  firstName,
  lastName,
  email,
  password,
  token,
  language
) => {
  const response = await axios.post(`/auth/signup`, {
    firstName,
    lastName,
    email,
    password,
    token,
    language,
  });

  return response;
};

export const signOut = () => {
  return axios.post('/auth/sign-out');
};
