import axios from 'axios';
import campaignsService from '../../services/campaigns/campaigns.service';

export function updateBillingAddress(clientId, billingAddress) {
  if (!clientId || !billingAddress) return false;
  return axios
    .put(`/clients/${clientId}/billingAddress`, {
      newBillingAddress: billingAddress,
    })
    .then((res) => true)
    .catch((error) => false);
}

export async function createCampaign(campaign, saveCampaign, client, user) {
  if (!campaign || !campaign.campaign_type) return;

  // Create campaign object
  const newCampaign = {
    campaign_type: campaign.campaign_type,
    campaign_name: campaign.campaign_name,
    deferred: campaign.deferred,
    destinations: [],
    company: client.company.name,
    whitelisting: client.whitelisting,
    owner: user.email,
    // items passed in the customization object
    // will be used to template the phishing email html
    // keys in this object need to match whatever is in the template
    // using python templates so "company" value will subsitute "$company"
    // TODO: eventually use user input for this?
    customization: {
      company: client.company.name,
      email: user.email, // TODO: or client.email? likely user.email is the new one
      // endDate: some campaigns may require "this offer ends by $endDate"
    },
  };

  if (campaign.campaign_targets === 'All') {
    newCampaign.destinations = client.emailList.filter(
      (dest) => dest.email !== client.email && dest.include_in_phishing
    );
  }

  if (campaign.campaign_targets === 'Selected') {
    newCampaign.destinations = campaign.campaign_recipients;
    if (campaign.campaign_launch_date) {
      newCampaign.start_date = campaign.campaign_launch_date;
    }
  }

  // If test campaign or user hasn't set up users
  if (!newCampaign.destinations || newCampaign.destinations.length === 0) {
    newCampaign.destinations = [
      {
        email: user.email,
        first_name: user.firstName,
        last_name: user.lastName,
      },
    ];
  }

  if (!saveCampaign) {
    try {
      await campaignsService.createTestCampaign(newCampaign);
    } catch (err) {
      throw new Error(err);
    }

    return;
  }

  newCampaign.destinations = newCampaign.destinations.map((item) => ({
    ...item,
    first_name: item.firstName,
    last_name: item.lastName,
  }));

  try {
    await campaignsService.createCampaign(newCampaign);
  } catch (err) {
    throw new Error(err);
  }
}

export default false;
