import axios from 'axios';

const deleteCampaign = (id) => {
  return axios.delete(`/campaigns/client/campaign/${id}`);
};

const createTestCampaign = (campaign) => {
  return axios.post('/campaigns', campaign);
};

const createCampaign = (campaign) => {
  return axios.post(`/campaigns/client`, campaign);
};

export default { deleteCampaign, createTestCampaign, createCampaign };
