import {
  faBoxTaped,
  faScanner,
  faFileInvoiceDollar,
  faFileImport,
  faVirus,
  faMessageSmile,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from '../../i18n';
import { showPopUp } from '../../redux/actions/popUp.actions';
import { TrackButton } from '../../track';

function useCampaign() {
  const client = useSelector((redux) => redux.client);
  const dispatch = useDispatch();
  const i18n = useTranslation();
  const { lang } = useTranslation();
  const navigate = useNavigate();
  const phishingCampaigns = useSelector((redux) => redux.campaigns);

  const onClickCampain = () => {
    if (
      phishingCampaigns.length >=
      client?.status?.serviceLevel.limits.maxPhishingCampaigns
    ) {
      dispatch(showPopUp('maxPhisingCampaignsPopUp'));
      return;
    }
    navigate('/client/new-phising-simulator');
    TrackButton('phishingSimulator.campaigns.add');
  };

  const onClickTeam = () => {
    navigate('/client/settings/employees');
    TrackButton('phishingSimulator.campaigns.teamSettings');
  };

  const sendCampaignResults = (campaign) => {
    if (campaign) {
      // Prevent sending multiple times the same email
      if (campaign.results_sent_date) {
        dispatch(
          showPopUp('notification', {
            notificationType: 'warning',
            title: '',
            text: i18n.t('phishingSimulator.campaignSummary.alreadySent'),
          })
        );
        return;
      }

      const clientId = client.id;
      const campaignId = campaign.campaign_id;
      const companyName = client.company.name;
      const campaignName = i18n.t(
        `phishingSimulator.campaigns.campaingTypes.${
          campaign.campaign_type.split('-')[0]
        }`
      );

      axios
        .post(`/send_phishing_results/${clientId}/${campaignId}`, {
          campaign_name: campaignName,
          company_name: companyName,
        })
        .then(() => {
          dispatch(
            showPopUp('notification', {
              notificationType: 'success',
              title: '',
              text: i18n.t('phishingSimulator.campaignSummary.trainingSent'),
            })
          );
        })
        .catch(() => {
          dispatch(
            showPopUp('notification', {
              notificationType: 'error',
              title: '',
              text: i18n.t('common.errorTryLater'),
            })
          );
        });
    }
  };

  const getNumberTrainingsCompleted = (campaign, trainingResults) => {
    let numberTrainingsCompleted = 0;
    if (!campaign || !campaign.destinations) return 0;
    campaign.destinations.forEach((recipient) => {
      if (trainingResults.completed.includes(recipient.email)) {
        numberTrainingsCompleted += 1;
      }
    });
    return numberTrainingsCompleted;
  };

  const getCampaignSummary = (campaign) => {
    if (!campaign) return false;
    const { results } = campaign;
    if (!results) return false;
    const newResult = {};
    newResult.clickRatio = 0;
    newResult.emailsClicked = 0;
    newResult.emailsSent = 0;
    newResult.id = campaign.campaign_id;
    newResult.launchDate = campaign.launch_date;
    newResult.name = campaign.campaign_name;
    newResult.type = campaign.campaign_type;

    results.forEach((item) => {
      switch (item.status) {
        case 'Clicked Link':
          newResult.emailsClicked += 1;
          newResult.emailsSent += 1;
          break;
        case 'Email Opened':
          break;
        case 'Email Sent':
          newResult.emailsSent += 1;
          break;
        default:
      }
    });

    if (newResult.emailsSent > 0) {
      newResult.clickRatio = newResult.emailsClicked / newResult.emailsSent;
    }

    return newResult;
  };

  const getCampaignTeamMemberSummary = (campaigns, teamMembers) => {
    // Compute totals per email over all campaigns
    const campaignTeamMemberSummary = {};

    campaigns.forEach((campain) => {
      campain.results.forEach((result) => {
        const teamMember = teamMembers?.find(
          (member) => member.email === result.email
        );
        let emailResult = campaignTeamMemberSummary[result.email];
        if (!emailResult) {
          emailResult = {
            email: result.email,
            first_name: teamMember?.firstName ?? result.first_name,
            last_name: teamMember?.lastName ?? result.last_name,
            last_email_sent: null,
            tags: teamMember?.tags ?? [],
            role: teamMember?.role ?? [],
          };
        }

        campaignTeamMemberSummary[result.email] = createResultMetrics(
          emailResult,
          result.status,
          campain.launch_date
        );
      });
    });

    // Convert dictionary to array
    const totals = [];
    for (const email in campaignTeamMemberSummary) {
      totals.push(campaignTeamMemberSummary[email]);
    }
    return totals;
  };

  const createResultMetrics = (result, status, date) => {
    const newResult = { ...result };
    // Filling emails_sent, emails_clicked, data_disclosed
    // if (!newResult.data_disclosed) {
    //   newResult.data_disclosed = 0;
    // }
    if (!newResult.emails_clicked) {
      newResult.emails_clicked = 0;
    }
    if (!newResult.emails_sent) {
      newResult.emails_sent = 0;
    }
    switch (status) {
      case 'Submitted Data':
        // newResult.data_disclosed += 1;
        newResult.emails_sent += 1;
        break;
      case 'Clicked Link':
        newResult.emails_clicked += 1;
        newResult.emails_sent += 1;
        break;
      case 'Email Opened':
        break;
      case 'Email Sent':
        newResult.emails_sent += 1;
        break;
      default:
        break;
    }

    // Filling last_email_sent
    if (!result.last_email_sent || date > result.last_email_sent) {
      newResult.last_email_sent = date;
    }
    return newResult;
  };

  const getCampaignsType = (key) => {
    return lang === 'en' ? key : `${key}-${lang}`;
  };

  const CAMPAINGS_TYPE = {
    generic_delivery_campaign: getCampaignsType('generic_delivery_campaign'),
    printer_document_campaign: getCampaignsType('printer_document_campaign'),
    electricity_campaign: getCampaignsType('electricity_campaign'),
    fileshare_campaign: getCampaignsType('fileshare_campaign'),
    coronavirus_campaign: getCampaignsType('coronavirus_campaign'),
    feedback_campaign: getCampaignsType('feedback_campaign'),
  };

  const PHISHING_CAMPAINGS = {
    [CAMPAINGS_TYPE.generic_delivery_campaign]: {
      type: CAMPAINGS_TYPE.generic_delivery_campaign,
      name: 'generic_delivery_campaign',
      img: <FontAwesomeIcon icon={faBoxTaped} />,
    },
    [CAMPAINGS_TYPE.printer_document_campaign]: {
      type: CAMPAINGS_TYPE.printer_document_campaign,
      name: 'printer_document_campaign',
      img: <FontAwesomeIcon icon={faScanner} />,
    },
    [CAMPAINGS_TYPE.electricity_campaign]: {
      type: CAMPAINGS_TYPE.electricity_campaign,
      name: 'electricity_campaign',
      img: <FontAwesomeIcon icon={faFileInvoiceDollar} />,
    },
    [CAMPAINGS_TYPE.fileshare_campaign]: {
      type: CAMPAINGS_TYPE.fileshare_campaign,
      name: 'fileshare_campaign',
      img: <FontAwesomeIcon icon={faFileImport} />,
    },
    [CAMPAINGS_TYPE.coronavirus_campaign]: {
      type: CAMPAINGS_TYPE.coronavirus_campaign,
      name: 'coronavirus_campaign',
      img: <FontAwesomeIcon icon={faVirus} />,
    },
    [CAMPAINGS_TYPE.feedback_campaign]: {
      type: CAMPAINGS_TYPE.feedback_campaign,
      name: 'feedback_campaign',
      img: <FontAwesomeIcon icon={faMessageSmile} />,
    },
  };

  return {
    onClickCampain,
    onClickTeam,
    sendCampaignResults,
    getNumberTrainingsCompleted,
    getCampaignSummary,
    getCampaignTeamMemberSummary,
    PHISHING_CAMPAINGS,
  };
}

export default useCampaign;
