import React, { useState } from 'react';
import {
  faDesktop,
  faEye,
  faLock,
  faLockOpen,
  faMobile,
  faServer,
  faShieldPlus,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faAndroid,
  faApple,
  faWindows,
  faLinux,
} from '@fortawesome/free-brands-svg-icons';
import {
  faCircleInfo,
  faCircleQuestion,
  faDownload,
} from '@fortawesome/pro-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Select, Switch } from 'antd';
import moment from 'moment';
import SCDevicesSummary, { SCTypeIcons } from './DevicesSummary.style';
import SectionCard from '../../molecules/SectionCard/SectionCard';
import TableContainer from '../../molecules/TableContainer/TableContainer';
import Icon from '../../atoms/Icon/Icon';
import InfoBanner from '../../molecules/InfoBanner/InfoBanner';
import useLicenses from '../../../hooks/licenses/useLicenses';
import Label from '../../atoms/Label/Label';
import { useTranslation } from '../../../i18n';
import useFilterTable from '../../../hooks/useFilterTable/useFilterTable';
import TagList from '../../molecules/TagList/TagList';
import Tooltip from '../../atoms/Tooltip/Tooltip';
import Button from '../../atoms/Button/Button';
import deviceProtectionService from '../../../services/deviceProtection/deviceProtection.service';
import { showPopUp } from '../../../redux/actions/popUp.actions';
import { getLicensesByType } from '../../../utils/functions/licenses';
import { downloadBlob } from '../../../utils/functions/download';

const getOSName = (osDBName) => osDBName.split(' ')[0].toLowerCase();

const normalizeDeviceType = (deviceType) => deviceType.toLowerCase();

const DEVICE_TYPES = {
  DESKTOP: 'desktop',
  SERVER: 'server',
  MOBILE: 'mobile',
};

const UNINSTALL_DEVICES_TYPES = {
  ALLOW: 'toAllow',
  DISALLOW: 'toDisallow',
};

const STATUS = {
  OUTDATED: 'OUTDATED',
  UPDATED: 'UPDATED',
};

const UNINSTALL_PROTECTIONS_URLS = {
  es: 'https://cyberguardian.tawk.help/article/como-desinstalo-las-protecciones-cyber-guardian-en-dispositivos-de-escritorio-y-servidores',
  en: 'https://cyberguardian.tawk.help/en-us/article/how-do-i-uninstall-cyber-guardian-protections-on-desktop-devices-and-servers',
  'pt-BR':
    'https://cyberguardian.tawk.help/pt-br/article/como-posso-desinstalar-as-protecaooes-do-cyber-guardian-em-dispositivos-pessoais-e-servidores',
};

const DevicesSummary = () => {
  const teamMembers = useSelector((redux) => redux.teamMembers);
  const protectedDevices = useSelector((redux) => redux.protectedDevices);
  const { licenses, licensesSent, licensesUsed } = useLicenses();
  const { malwareProtectionLicenses, webProtectionLicenses } =
    getLicensesByType(licenses, licensesSent, licensesUsed);

  const i18n = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [deviceFilter, setDeviceFilter] = useState([]);
  const [selectedDeviceIds, setSelectedDeviceIds] = useState([]);
  const [loadingDevices, setLoadingDevices] = useState({});
  const [isDownloadingDevices, setIsDownloadingDevices] = useState(false);

  const {
    availableToSend: malwareAvailableToSend,
    sentNotInstalled: malwareSentNotInstalled,
  } = malwareProtectionLicenses;
  const {
    availableToSend: webAvailableToSend,
    sentNotInstalled: webSentNotInstalled,
  } = webProtectionLicenses;

  const hasAvailableToSendLicenses =
    malwareAvailableToSend > 0 || webAvailableToSend > 0;
  const hasSentNotInstalledLicenses =
    malwareSentNotInstalled > 0 || webSentNotInstalled > 0;

  const uninstallProtectionsFAQ =
    UNINSTALL_PROTECTIONS_URLS[i18n.getLanguage()] ??
    UNINSTALL_PROTECTIONS_URLS.en;

  const protectedDevicesMap = protectedDevices.reduce(
    (accumulator, currentValue) => {
      accumulator[currentValue.id] = currentValue;
      return accumulator;
    },
    {}
  );

  const isMobile = (deviceId) => {
    const item = protectedDevicesMap[deviceId];
    return normalizeDeviceType(item.type) === DEVICE_TYPES.MOBILE;
  };

  const disableUnistallButtons = selectedDeviceIds.every((selectedDeviceId) =>
    isMobile(selectedDeviceId)
  );

  const nonMobileProtectedDevices = selectedDeviceIds.filter(
    (selectedDeviceId) => !isMobile(selectedDeviceId)
  );

  const getCustomBottomElementsText = () => {
    if (selectedDeviceIds.length === 0) {
      return i18n.t('deviceSecurity.deviceSecuritySummary.selectDevices');
    }

    if (disableUnistallButtons) {
      return i18n.t(
        'deviceSecurity.deviceSecuritySummary.nonMobileDevicesAllowed'
      );
    }

    return i18n.t('deviceSecurity.deviceSecuritySummary.selectedDevices', {
      nDevices: selectedDeviceIds.length,
    });
  };

  const handleGenericError = () => {
    dispatch(
      showPopUp('notification', {
        notificationType: 'error',
        title: i18n.t('common.error'),
        text: i18n.t('errors.not_found'),
      })
    );
  };

  const getTeamMembersTags = () => {
    const teamMembersMap = {};

    teamMembers.forEach(({ email, tags, role }) => {
      teamMembersMap[email] = { tags: tags ?? [], role };
    });

    return teamMembersMap;
  };

  const teamMembersMap = getTeamMembersTags();

  const protectedDevicesWithTeamMember = protectedDevices?.map(
    (protectedDevice) => ({
      ...protectedDevice,
      ...teamMembersMap[protectedDevice.email],
    })
  );

  const filteredProtectedDevices =
    deviceFilter.length > 0
      ? protectedDevicesWithTeamMember.filter((protectedDevice) => {
          return deviceFilter.includes(
            normalizeDeviceType(protectedDevice.type)
          );
        })
      : protectedDevicesWithTeamMember;

  const deviceTypeIcons = (type, os) => {
    const iconsProps = {
      color: 'var(--bluish-grey-2)',
      size: 'medium',
    };

    const ICONS = {
      unknown: faCircleQuestion,
      type: {
        server: faServer,
        desktop: faDesktop,
        mobile: faMobile,
      },
      os: {
        windows: faWindows,
        macos: faApple,
        android: faAndroid,
        ios: faApple,
        linux: faLinux,
      },
    };

    return (
      <SCTypeIcons>
        <Icon iconName={ICONS.type[type] ?? ICONS.unknown} {...iconsProps} />
        <Icon iconName={ICONS.os[os] ?? ICONS.unknown} {...iconsProps} />
      </SCTypeIcons>
    );
  };

  const getUninstallSortValue = (deviceType, uninstallValue) => {
    if (normalizeDeviceType(deviceType) === DEVICE_TYPES.MOBILE) {
      return 0;
    }

    if (!uninstallValue) {
      return 1;
    }

    return 2;
  };

  const getLabelConfig = (protectionTool) => {
    const { active, status } = protectionTool;

    const protectionToolConfig = {
      inactive: {
        text: i18n.t('deviceSecurity.deviceSecuritySummary.syncronizing'),
        color: 'white',
      },
      updated: {
        text: i18n.t('deviceSecurity.deviceSecuritySummary.active'),
        color: 'default',
      },
      outdated: {
        text: i18n.t('deviceSecurity.deviceSecuritySummary.disconnected'),
        color: 'orange',
      },
      notApplicable: {
        text: i18n.t('deviceSecurity.deviceSecuritySummary.notApplicable'),
        color: 'default',
      },
    };

    if (!protectionTool.shouldInstall) {
      return protectionToolConfig.notApplicable;
    }

    if (active && status === STATUS.UPDATED) {
      return protectionToolConfig.updated;
    }

    if (active && status === STATUS.OUTDATED) {
      return protectionToolConfig.outdated;
    }

    return protectionToolConfig.inactive;
  };

  const mapperFn = (item) => {
    const malwareConfig = getLabelConfig(item.malware);
    const webProtectionConfig = getLabelConfig(item.webprotection);
    const deviceType = normalizeDeviceType(item?.type);

    return {
      id: {
        type: 'string',
        value: item?.id,
      },
      device: {
        type: 'component',
        value: (
          <Tooltip tooltip={<p>{item?.devicename}</p>}>
            <p>{item?.devicename}</p>
          </Tooltip>
        ),
        sortValue: item?.devicename,
      },
      deviceType: {
        type: 'component',
        value: deviceTypeIcons(deviceType, getOSName(item?.osversion)),
      },
      deviceProtection: {
        type: 'component',
        value: (
          <Label
            width="115px"
            value={malwareConfig.text}
            color={malwareConfig.color}
          />
        ),
        sortValue: malwareConfig.text,
      },
      webProtection: {
        type: 'component',
        value: (
          <Label
            width="115px"
            value={webProtectionConfig.text}
            color={webProtectionConfig.color}
          />
        ),
        sortValue: webProtectionConfig.text,
        color:
          deviceType === DEVICE_TYPES.DESKTOP && !item?.webprotection?.active
            ? 'var(--red)'
            : 'initial',
      },
      allowUninstall: {
        type: 'component',
        value:
          deviceType !== DEVICE_TYPES.MOBILE ? (
            <Switch
              id={item.id}
              size="default"
              checked={item?.allowUninstall}
              loading={loadingDevices[item.id]}
              onChange={(checked) => {
                setLoadingDevices((prev) => ({ ...prev, [item.id]: true }));
                const uninstallDevicesType = checked
                  ? UNINSTALL_DEVICES_TYPES.ALLOW
                  : UNINSTALL_DEVICES_TYPES.DISALLOW;

                deviceProtectionService
                  .uninstallDevicesBatch({
                    [uninstallDevicesType]: [item.id],
                  })
                  .catch(handleGenericError)
                  .finally(() => {
                    setLoadingDevices((prev) => {
                      const { [item.id]: _, ...rest } = prev;
                      return rest;
                    });
                  });
              }}
            />
          ) : (
            <Label
              value={i18n.t(
                'deviceSecurity.deviceSecuritySummary.notApplicable'
              )}
            />
          ),
        sortValue: getUninstallSortValue(item.type, item.allowUninstall),
      },
      email: {
        type: 'component',
        value: (
          <Tooltip tooltip={<p>{item?.email}</p>}>
            <p>{item?.email}</p>
          </Tooltip>
        ),
        sortValue: item?.email,
      },
      role: {
        type: 'component',
        value: item.tags ? <TagList teamMember={item} cell /> : undefined,
      },
    };
  };

  const PAGE_SIZE = 10;

  const sortOptions = {
    activeSortedColumnName: i18n.t(
      `deviceSecurity.deviceSecuritySummary.email`
    ),
    activeSortedColumnDirection: undefined,
    activeSortedColumnIndex: 0,
    onSort: (data) => {
      setSort(data);
    },
  };

  const { setSort, setSearch, setSearchTags, rows, length } = useFilterTable(
    PAGE_SIZE,
    mapperFn,
    filteredProtectedDevices,
    ['email', 'devicename'],
    sortOptions
  );

  const columns = [
    {
      name: 'id',
      options: {
        hide: true,
      },
    },
    {
      name: i18n.t(`deviceSecurity.deviceSecuritySummary.device`),
      options: {
        ofuscateFS: true,
        stickyOnScroll: true,
        width: 150,
        stickyOffset: 40,
        ellipsisContent: true,
      },
    },
    {
      name: i18n.t(`deviceSecurity.deviceSecuritySummary.deviceType`),
      options: {
        avoidSort: true,
      },
    },
    {
      name: i18n.t(`deviceSecurity.deviceSecuritySummary.deviceProtection`),
    },
    {
      name: i18n.t(`deviceSecurity.deviceSecuritySummary.webProtection`),
    },
    {
      name: i18n.t('deviceSecurity.deviceSecuritySummary.allowUninstall'),
      infoOnClick: () => {
        window.open(uninstallProtectionsFAQ);
      },
      options: {
        width: 175,
      },
    },
    {
      name: i18n.t(`deviceSecurity.deviceSecuritySummary.email`),
      options: {
        ofuscateFS: true,
        ellipsisContent: true,
        width: 175,
      },
    },
    {
      name: i18n.t(`manageEmailList.tags`),
      options: {
        avoidSort: true,
      },
    },
  ];

  const handleUninstallDevices = (uninstallDevicesType) => {
    // Loading
    setLoadingDevices((prev) => {
      const auxSelectedDevices = {};
      nonMobileProtectedDevices.forEach((selectedDeviceId) => {
        auxSelectedDevices[selectedDeviceId] = true;
      });

      return { ...prev, ...auxSelectedDevices };
    });

    deviceProtectionService
      .uninstallDevicesBatch({
        [uninstallDevicesType]: nonMobileProtectedDevices,
      })
      .catch(handleGenericError)
      .then(() => {
        if (uninstallDevicesType === UNINSTALL_DEVICES_TYPES.ALLOW) {
          const nSelectedDevices = selectedDeviceIds.length;
          const nNonMobileDevices = nonMobileProtectedDevices.length;
          const nMobileDevices = nSelectedDevices - nNonMobileDevices;

          dispatch(
            showPopUp('notification', {
              notificationType: 'success',
              title: i18n.t('common.success'),
              closeButtonText: i18n.t('common.close'),
              text: i18n.t(
                nNonMobileDevices === 1
                  ? 'deviceSecurity.deviceSecuritySummary.successUninstallNonMobile'
                  : 'deviceSecurity.deviceSecuritySummary.successUninstallNonMobiles',
                { nNonMobileDevices }
              ),
              ...(nMobileDevices > 0 && {
                subtext: i18n.t(
                  nMobileDevices === 1
                    ? 'deviceSecurity.deviceSecuritySummary.successUninstallMobile'
                    : 'deviceSecurity.deviceSecuritySummary.successUninstallMobiles',
                  { nMobileDevices }
                ),
              }),
            })
          );
        }
      })
      .finally(() => {
        // Quitar loading
        setLoadingDevices({});
      });
  };

  const getPendingLicensesInfoBannerProps = () => {
    if (hasAvailableToSendLicenses) {
      return {
        text: [i18n.t('deviceSecurity.hasAvailableToSendLicenses')],
        onClickButton: () =>
          navigate('/client/device-security/install-protections'),
        buttonText: i18n.t(
          'deviceSecurity.deviceSecuritySummary.installProtections'
        ),
        buttonIcon: faShieldPlus,
      };
    }

    return {
      text: [i18n.t('deviceSecurity.hasSentNotInstalledLicenses')],
    };
  };

  const handleExport = () => {
    setIsDownloadingDevices(true);

    deviceProtectionService
      .exportDevicesToXlsx()
      .then((resp) => {
        const date = moment(new Date()).format('YYYY-MM-DD');
        const fileName = `list-devices-security-${date}.xlsx`;
        downloadBlob(resp, fileName);
      })
      .catch(() => {
        dispatch(
          showPopUp('notification', {
            notificationType: 'error',
            title: i18n.t('common.error'),
            text: i18n.t('errors.not_found'),
          })
        );
      })
      .finally(() => {
        setIsDownloadingDevices(false);
      });
  };

  return (
    <SCDevicesSummary>
      <SectionCard
        headerIcon={faEye}
        headerTitle={`${i18n.t(
          'deviceSecurity.deviceSecuritySummary.overviewOfMyDevices'
        )} (${length})`}>
        {(hasAvailableToSendLicenses || hasSentNotInstalledLicenses) && (
          <div className="infoBanner-container">
            <InfoBanner
              type="defaultStyle"
              {...getPendingLicensesInfoBannerProps()}
            />
          </div>
        )}

        <TableContainer
          columns={columns}
          rows={rows}
          emptyMessage={i18n.t(
            'deviceSecurity.deviceSecuritySummary.noDevices'
          )}
          onChangeValue={setSearch}
          onChangeFilterTag={setSearchTags}
          totalCount={length}
          sortOptions={sortOptions}
          hasHorizontalScroll
          pageSize={PAGE_SIZE}
          rowIdentifier="id"
          hasRowSelect
          rowHasBeenSelected={(selectedRows) => {
            setSelectedDeviceIds(selectedRows.map((row) => row.id.value));
          }}
          customSideElements={
            <>
              <Select
                mode="multiple"
                allowClear
                style={{ flex: 1, minWidth: '225px' }}
                placeholder={i18n.t(
                  'deviceSecurity.deviceSecuritySummary.filterPlaceholder'
                )}
                defaultValue={[]}
                onChange={setDeviceFilter}
                options={[
                  {
                    value: DEVICE_TYPES.DESKTOP,
                    label: i18n.t(
                      'deviceSecurity.deviceSecuritySummary.desktop'
                    ),
                  },
                  {
                    value: DEVICE_TYPES.SERVER,
                    label: i18n.t(
                      'deviceSecurity.deviceSecuritySummary.server'
                    ),
                  },
                  {
                    value: DEVICE_TYPES.MOBILE,
                    label: i18n.t(
                      'deviceSecurity.deviceSecuritySummary.mobile'
                    ),
                  },
                ]}
              />

              <Button
                text={i18n.t('deviceSecurity.deviceSecuritySummary.exportXlsx')}
                icon={faDownload}
                color="white"
                onClick={handleExport}
                size="mid"
                loading={isDownloadingDevices}
              />
            </>
          }
          customBottomElements={
            <div className="selected-row-actions">
              <div className="selected-row-actions-info">
                <Icon iconName={faCircleInfo} color="var(--bluish-grey)" />

                <p>{getCustomBottomElementsText()}</p>
              </div>

              {selectedDeviceIds.length > 0 && !disableUnistallButtons && (
                <div className="buttons-container">
                  <Button
                    onClick={() => {
                      handleUninstallDevices(UNINSTALL_DEVICES_TYPES.DISALLOW);
                    }}
                    icon={faLock}
                    text={i18n.t(
                      'deviceSecurity.deviceSecuritySummary.disallowUninstall'
                    )}
                    color="white"
                    size="customMid"
                    disabled={disableUnistallButtons}
                  />

                  <Button
                    onClick={() => {
                      handleUninstallDevices(UNINSTALL_DEVICES_TYPES.ALLOW);
                    }}
                    icon={faLockOpen}
                    text={i18n.t(
                      'deviceSecurity.deviceSecuritySummary.allowUninstall'
                    )}
                    color="white"
                    size="customMid"
                    disabled={disableUnistallButtons}
                  />
                </div>
              )}
            </div>
          }
        />
      </SectionCard>
    </SCDevicesSummary>
  );
};

export default DevicesSummary;
