import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ErrorKeyCustomized } from '../../track';
import * as authService from '../../services/auth/auth.service';
import { auth } from '../../firebase';
import { useTranslation } from '../../i18n';
import { signOut } from '../../redux/actions/auth.actions';

const useAuth = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const i18n = useTranslation();

  const handleRedirection = () => {
    if (!auth.currentUser.emailVerified) {
      auth.languageCode = i18n.getLanguage();
      navigate('/account-created');
    } else {
      navigate('/client');
    }
  };

  const signUp = (firstName, lastName, email, password, token, language) => {
    setLoading(true);
    setError(false);

    return authService
      .signUp(firstName, lastName, email, password, token, language)
      .then((res) => {
        if (auth.currentUser) {
          handleRedirection();
        } else {
          auth.signInWithEmailAndPassword(email, password).then(() => {
            handleRedirection();
          });
        }
      })
      .catch((err) => {
        if (err.response.status === 409) {
          console.log(err.response?.data);
          ErrorKeyCustomized({
            i18nKey: 'errors.alreadyRegistered',
            label: 'saveNewClient3',
          });
        } else {
          console.log(err);
          ErrorKeyCustomized({
            i18nKey: 'errors.thereWasAnError',
            label: 'saveNewClient3',
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const signOutFromApp = async () => {
    setLoading(true);

    try {
      await authService.signOut();
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(signOut());
      setLoading(false);
    }
  };

  return {
    loading,
    error,
    signUp,
    signOutFromApp,
  };
};

export default useAuth;
