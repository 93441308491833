import React, { useState } from 'react';

import { faPowerOff, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { useNavigate } from 'react-router-dom';
import Icon from '../../atoms/Icon/Icon';
import NavMenu from '../NavMenu/NavMenu';
import NavMenuMobile from '../NavMenuMobile/NavMenuMobile';
import Burger from '../../../img/burger.svg';
import { ReactComponent as CyberGuardianLogo } from '../../../img/cyberguardian/cyberguardian-logo.svg';
import { ReactComponent as SantanderLogo } from '../../../img/cyberguardian/SantanderFullLogo.svg';
import { auth } from '../../../firebase';
import { PUBLIC_HOME } from '../../../utils/constants/urls';
import SCNavbar from './Navbar.style';
import Button from '../../atoms/Button/Button';
import { useTranslation } from '../../../i18n';
import useAuth from '../../../hooks/auth/useAuth';
import SpinnerText from '../../atoms/SpinnerText/SpinnerText';

const Navbar = ({ hideAuthButtons, showSantanderLogo, showSignOutButton }) => {
  const navigate = useNavigate();
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const i18n = useTranslation();
  const { signOutFromApp, loading: loadingSignOut } = useAuth();

  const handleLogo = () => {
    if (auth.currentUser) {
      navigate('/client');
    } else {
      window.location.href = PUBLIC_HOME;
    }
  };

  return (
    <SCNavbar>
      <CyberGuardianLogo className="cyber-guardian-logo" onClick={handleLogo} />
      {showSantanderLogo && <SantanderLogo className="santander-logo" />}

      {!hideAuthButtons && (
        <>
          <div
            role="button"
            tabIndex={0}
            className="burger-menu"
            onKeyDown={() => setShowMobileMenu(!showMobileMenu)}
            onClick={() => setShowMobileMenu(!showMobileMenu)}>
            {!showMobileMenu ? (
              <img src={Burger} alt="burger" />
            ) : (
              <Icon
                size="navIcon"
                iconName={faXmark}
                color="var(--bluish-grey)"
              />
            )}
          </div>

          <NavMenu />

          <NavMenuMobile showMobileMenu={showMobileMenu} />
        </>
      )}

      {showSignOutButton && (
        <Button
          icon={!loadingSignOut ? faPowerOff : undefined}
          text={
            loadingSignOut ? (
              <SpinnerText text={i18n.t('common.loading')} />
            ) : (
              i18n.t('common.signOut')
            )
          }
          color="white"
          size="mid"
          className="sign-out-button"
          onClick={signOutFromApp}
          disabled={loadingSignOut}
        />
      )}
    </SCNavbar>
  );
};

export default Navbar;
