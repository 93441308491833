import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import Icon from '../Icon/Icon';
import SCButton from './Button.style';

const Button = ({
  onClick,
  icon,
  text,
  isHidden,
  size,
  width,
  color,
  disabled,
  loading,
  ...props
}) => {
  return (
    <SCButton
      type="button"
      onClick={onClick}
      isHidden={isHidden}
      size={size}
      color={color}
      width={width}
      disabled={loading || disabled}
      {...props}>
      <div className="icon-text-container">
        {icon &&
          (loading ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : (
            <Icon iconName={icon} size="medium" />
          ))}
        {text}
      </div>
    </SCButton>
  );
};

export default Button;
