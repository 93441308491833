import axios from 'axios';

const sendLicenses = ({ recipients, deferred }) => {
  return axios.post('/device-protection/send', {
    recipients,
    deferred,
  });
};

const uninstallDevicesBatch = ({ toAllow, toDisallow }) => {
  return axios.post('/device-protection/uninstall/batch', {
    toAllow,
    toDisallow,
  });
};

const revokeToken = ({ tokenValue }) => {
  return axios.post('/device-protection/revoke-token', {
    tokenValue,
  });
};

const getIosToken = () => {
  return axios.get('/device-protection/ios-token');
};

const getPartnerClientLicensesCounter = (clientId) => {
  return axios.get(`/device-protection/partners/licenses-counter/${clientId}`);
};

const exportDevicesToXlsx = () => {
  return axios.get('/device-protection/devices/export', {
    responseType: 'blob',
  });
};

export default {
  sendLicenses,
  uninstallDevicesBatch,
  revokeToken,
  getIosToken,
  getPartnerClientLicensesCounter,
  exportDevicesToXlsx,
};
