import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';
import sha1 from 'sha1';

import qs from 'query-string';
import {
  faEnvelopeOpen,
  faKeySkeleton,
} from '@fortawesome/pro-regular-svg-icons';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from '../../i18n';
import { TrackButton, ErrorKey, InfoKey } from '../../track';

import SCEmailVerification from './EmailVerification.style';
import Icon from '../../components/atoms/Icon/Icon';
import Button from '../../components/atoms/Button/Button';
import Input from '../../components/atoms/Input/Input';
import { showPopUp } from '../../redux/actions/popUp.actions';
import useAuth from '../../hooks/auth/useAuth';

const EmailVerificationWrapper = (props) => {
  const i18n = useTranslation();
  const navigate = useNavigate();
  const { signOutFromApp } = useAuth();

  return (
    <EmailVerification
      {...props}
      i18n={i18n}
      navigate={navigate}
      signOut={signOutFromApp}
    />
  );
};

export class EmailVerification extends Component {
  static generatePassword() {
    const length = 10;
    const charset =
      'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()';
    let retVal = '';
    for (let i = 0, n = charset.length; i < length; i += 1) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }

  constructor(props) {
    super(props);
    const queries = qs.parse(window.location.search);
    this.state = {
      showDialog: false,
      oobCode: queries.oobCode,
      continueUrl: queries.continueUrl,
      mode: queries.mode,
      showPassword: true,
    };
  }

  componentDidMount() {
    const { firebase, dispatch, i18n } = this.props;
    const auth = firebase.auth();
    const { oobCode, mode, continueUrl } = this.state;
    if (oobCode && mode === 'signIn') {
      if (continueUrl) {
        window.location.href = continueUrl + window.location.search;
      }
    }
    if (oobCode && mode === 'verifyEmail') {
      auth
        .applyActionCode(oobCode)
        .then(() => {
          if (continueUrl) {
            window.location.href = continueUrl;
          }
        })
        .catch((e) => {
          dispatch(
            showPopUp('emailVerificationExpired', {
              onButtonClick: this.sendEmailVerification,
            })
          );
        });
    }
    if (auth && auth.currentUser && auth.currentUser.emailVerified) {
      if (continueUrl) {
        window.location.href = continueUrl;
      }
      // window.location.href = '/';
    }
  }

  componentWillReceiveProps(nextProps) {
    const { auth } = this.props;
    const { continueUrl } = this.state;
    if (
      !auth.isLoaded &&
      nextProps.auth.isLoaded &&
      this.state.mode === 'verifyEmail'
    ) {
      if (nextProps.auth.isEmpty) {
        // its not signed in redirect to signed-in
        // navigate('/sign-in');
      }
      if (
        nextProps.auth &&
        nextProps.auth.currentUser &&
        nextProps.auth.currentUser.emailVerified
      ) {
        // Email is verified redirect to dashboard
        if (continueUrl) {
          window.location.href = continueUrl;
        }
        // window.location.href = '/';
      }
    }
  }

  componentDidUpdate() {
    const { auth } = this.props;
    const { continueUrl } = this.state;
    if (auth && auth.currentUser && auth.currentUser.emailVerified) {
      if (continueUrl) {
        window.location.href = continueUrl;
      }
      // navigate('/');
    }
  }

  handleCloseSession() {
    const { signOut } = this.props;
    signOut();
  }

  sendEmailVerification = async () => {
    const { firebase, i18n } = this.props;

    // We get the language code from the subdomain function.
    // Perhaps it's not necessary because the language is already setted?
    firebase.auth().languageCode = i18n.lang;

    return firebase
      .auth()
      .currentUser.sendEmailVerification({ url: window.location.origin });
  };

  randomPassword() {
    this.setState({ password: EmailVerification.generatePassword() }, () =>
      this.checkPwnedPassword()
    );
  }

  changeHandlerText(e) {
    const { name, value } = e.target;
    if (name) {
      this.setState({ [name]: value });
    }
  }

  togglePassword() {
    const { showPassword } = this.state;
    if (showPassword) {
      this.setState({ showPassword: false });
    } else {
      this.setState({ showPassword: true });
    }
  }

  checkPwnedPassword() {
    const { password } = this.state;
    if (!password) return;
    const digit5 = sha1(password).substring(0, 5);
    const checkRest = sha1(password).replace(digit5, '');
    this.setState({ hasPwned: 0 });
    axios
      .get(`https://api.pwnedpasswords.com/range/${digit5}`)
      .then((res) => {
        const pass = res.data.split('\r\n');
        // check each row of array with hash passwords and see if user's password exists
        for (let i = 0; i < pass.length; i += 1) {
          if (pass[i].indexOf(checkRest.toUpperCase()) > -1) {
            // oops password has been pwned!
            this.setState({
              hasPwned: pass[i].substring(
                pass[i].indexOf(':') + 1,
                pass[i].length
              ),
              allowAutoPassword: true,
            });
            break;
          }
        }
      })
      .catch((error) => this.setState({ hasError: error }));
  }

  // Submit email to begin Passwordless transaction
  sendMagicLink() {
    this.sendEmailVerification()
      .then(this.setState({ showDialog: true }))
      .catch((e) => {
        if (e?.code === 'auth/too-many-requests') {
          ErrorKey('emailVerification.tooManyRequests');
        } else {
          ErrorKey('profile.tfa.error');
        }
        throw e;
      });
  }

  hideDialog() {
    const { navigate } = this.props;
    navigate('/sign-in');
  }

  changePassword() {
    const { firebase, navigate } = this.props;
    const auth = firebase.auth();
    const { password, password2, hasPwned, oobCode } = this.state;
    if (!password) {
      InfoKey('emailVerification.errorFillForm');
      return;
    }
    if (password.length < 8) {
      InfoKey('emailVerification.error10Digit');
      return;
    }
    if (hasPwned > 0) {
      InfoKey('emailVerification.errorPasswordsBreached');
      return;
    }
    auth
      .verifyPasswordResetCode(oobCode)
      .then((email) => {
        const accountEmail = email;

        // TODO: Show the reset screen with the user's email and ask the user for
        // the new password.

        // Save the new password.
        auth
          .confirmPasswordReset(oobCode, password)
          .then((resp) => {
            this.setState({ showDialog: true });
            setTimeout(() => {
              navigate('/sign-in');
            }, 3000);
          })
          .catch((e) =>
            InfoKey('errors.thereWasAnErrorWith', 'changePassword1', {
              errormessage: e,
            })
          );
      })
      .catch((error) =>
        InfoKey('errors.thereWasAnErrorWith', 'changePassword2', {
          errormessage: error,
        })
      );
  }

  render() {
    const { showDialog, oobCode, mode, password } = this.state;
    const { i18n } = this.props;
    if (mode === 'resetPassword') {
      return (
        <SCEmailVerification>
          <div className="email-verification-container">
            <Icon
              iconName={faKeySkeleton}
              size="extraLarge"
              color="var(--red)"
            />

            <h1>{i18n.t('emailVerification.passwordUpdate')}</h1>

            <p>{i18n.t('emailVerification.passwordRequirements')}</p>

            <Input
              inputType="password"
              id="password"
              name="password"
              size="large"
              inputPlaceholder={i18n.t('emailVerification.yourNewPassword')}
              defaultValue={password || ''}
              onChangeValue={(val) =>
                this.setState({ password: val.trim() }, () =>
                  this.checkPwnedPassword()
                )
              }
              center
            />

            {!showDialog ? (
              <Button
                text={i18n.t('emailVerification.changePassword')}
                onClick={() => {
                  this.changePassword();
                  TrackButton('emailVerification.changePassword');
                }}
                size="full"
                color="red"
              />
            ) : (
              <p className="email-link-sent">
                {i18n.t('emailVerification.passwordChanged')}
              </p>
            )}
          </div>
        </SCEmailVerification>
      );
    }

    return (
      <SCEmailVerification>
        <div className="email-verification-container">
          <Icon
            iconName={faEnvelopeOpen}
            size="extraLarge"
            color="var(--red)"
          />

          <h1>
            {!oobCode && i18n.t('emailVerification.verifyYourAccount')}
            {mode === 'verifyEmail' &&
              i18n.t('emailVerification.emailVerificationTitle')}
            {mode === 'signIn' && i18n.t('emailVerification.signInTitle')}
          </h1>

          {!oobCode && (
            <>
              <p>{i18n.t('emailVerification.emailRequiresVerification')}</p>
              {!showDialog ? (
                <Button
                  text={i18n.t('emailVerification.sendVerification')}
                  onClick={() => {
                    this.sendMagicLink();
                    TrackButton('emailVerification.sendVerification');
                  }}
                  size="full"
                  color="red"
                />
              ) : (
                <p className="email-link-sent">
                  {i18n.t('emailVerification.emailLinkSent')}
                </p>
              )}

              <a
                onClick={() => {
                  this.handleCloseSession();
                  TrackButton('emailVerification.orSignOut');
                }}>
                {i18n.t('emailVerification.orSignOut')}
              </a>
            </>
          )}

          {oobCode && (
            <div style={{ width: '100%' }}>
              <p
                className="sign-subheader px-3"
                style={{ fontSize: '18px', paddingBottom: '20px' }}>
                {mode === 'verifyEmail' &&
                  i18n.t('emailVerification.emailVerifying')}
                {mode === 'signIn' && i18n.t('emailVerification.redirecting')}
              </p>
            </div>
          )}
        </div>
      </SCEmailVerification>
    );
  }
}

export function mapStateToProps(state) {
  return {
    auth: state.auth,
    firebase: state.firebase,
    dispatch: state.dispatch,
  };
}

EmailVerification.propTypes = {
  firebase: PropTypes.object.isRequired, // eslint-disable-line
  auth: PropTypes.object.isRequired, //eslint-disable-line
  location: PropTypes.object.isRequired, //eslint-disable-line
  location: PropTypes.object.isRequired, //eslint-disable-line
  dispatch: PropTypes.object.isRequired, //eslint-disable-line
};

export default connect(mapStateToProps)(EmailVerificationWrapper);
